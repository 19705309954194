<template>
    <div v-loading="$wait.is('damages.loading')" class="w-full card" element-loading-spinner="el-custom-spinner">
        <ConfirmationModal codeConfirmation @success="getAll" />

        <ModalAddDamage
            :modalAddDamage="modalAddDamage"
            :employeeUuid="employeeUuid"
            @refresh="getAll"
            @close="modalAddDamage = false"
        />

        <ModalEditDamage
            :modalVisible="modalEditDamage"
            :damageData="selectedDamage"
            @refresh="getAll"
            @close="modalEditDamage = false"
        />

        <div class="flex items-center justify-between border-b border-gray-300 pb-4 mb-5">
            {{ $t('employee_details.damages') }}
            <el-button type="primary" @click="showModalAddDamage">
                {{ $t('common.add') }}
            </el-button>
        </div>

        <DamagesTable
            :damagesData="damagesData"
            @handleDelete="handleDelete"
            @handleEdit="handleEdit"
        />

        <NoDataInformation :data="noDataInformation" waitKey="damages.loading" />
    </div>
</template>
<script>
export default {
    components: {
        ModalAddDamage:  () => import(/* webpackChunkName: "EmployeeDamages/ModalAddDamage" */ './modals/ModalAddDamage'),
        ModalEditDamage: () => import(/* webpackChunkName: "EmployeeDamages/ModalEditDamage" */ './modals/ModalEditDamage'),
        DamagesTable:    () => import(/* webpackChunkName: "EmployeeDamages/DamagesTable" */ './components/DamagesTable'),
    },

    data() {
        return {
            noDataInformation: false,
            employeeUuid:      this.$route.params.uuid,
            selectedDamage:    {},
            damagesData:       [],
            modalAddDamage:    false,
            modalEditDamage:   false,
        };
    },

    beforeCreate() {
        this.$emit('activeSubpage', 'damages');
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('damages.loading');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/damages/employee/${this.employeeUuid}`);
            this.damagesData = data;
            this.noDataInformation = data.length;
            this.$wait.end('damages.loading');
        },

        showModalAddDamage() {
            this.modalAddDamage = true;
        },

        handleEdit(item) {
            this.selectedDamage = item;
            this.modalEditDamage = true;
        },

        handleDelete(uuid) {
            this.$store.commit('showConfirmationModal', {
                actionUrl:  `${process.env.VUE_APP_CORE_API_URL}/clients/damages/${uuid}`,
                actionType: 'destroy',
            });
        },
    },
};
</script>
